import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import {useRouter} from "@core/utils/utils";

export const useShopFiltersSortingAndPagination = () => {
  const { router } = useRouter();
  const filters = ref({
    q: "",
    categories: [],
    brands: [],
    page: 1,
    perPage: 9,
  });

  // Sorting
  const sortBy = ref({ name: "Stocks", slug: "all" });

  const sortByOptions = computed(() => {
    const stocks = [
      { name: "Stocks", slug: "all" },
        ...(store.getters["stock/all"])
    ]
    return stocks;
  })

  const filterOptions = computed(() => {
    return store.getters["item/allProperties"];
  });

  onMounted(() => {
    store.dispatch("item/fetchItemProperties", {
      props: ["categories", "brands"],
    });
    store.dispatch("stock/allStocks", router.currentRoute.params.id);
  });
  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = "grid-view";
  const itemViewOptions = [
    { icon: "GridIcon", value: "grid-view" },
    { icon: "ListIcon", value: "list-view" },
  ];

  // Pagination count <= required by pagination component
  const totalProducts = ref(null);

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  };
};

export const useShopRemoteData = () => {
  const products = ref([]);
  const fetchProducts = (...args) =>
    store.dispatch("storeManagement/fetchItems", ...args);

  return {
    products,
    fetchProducts,
  };
};
