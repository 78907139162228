<template>
  <validation-observer ref="refFormObserver" #default="{ invalid }">
    <b-form @submit.prevent="onSubmit" @reset="resetForm">
      <b-card class="card-stock shadow-none mx-0" no-body>
        <b-card-header>
          <b-card-title>
            {{ currentId ? "Modifier" : "Ajouter" }} Stock
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <!-- Field: Designation -->
          <validation-provider
            #default="{ errors }"
            name="designation"
            rules="required"
          >
            <b-form-group label="Désignation" label-for="designation">
              <b-form-input
                v-model="stockData.name"
                id="designation"
                placeholder="Veuillez entrer le nom de votre stock."
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Description -->
          <validation-provider
            #default="{ errors }"
            name="description"
            rules=""
          >
            <b-form-group label="Description :" label-for="description">
              <b-form-textarea
                id="description"
                v-model="stockData.description"
                placeholder="Description..."
                rows="1"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card-body>
        <b-card-footer class="d-flex justify-content-end">
          <b-button
            size="sm"
            class="mr-1"
            type="submit"
            variant="primary"
            :disabled="isLoading || invalid"
          >
            <b-spinner v-if="isLoading" small />
            {{ currentId ? "Mettre à jour" : "Enregistrer" }}
          </b-button>
          <b-button
            size="sm"
            type="reset"
            variant="outline-secondary"
            :disabled="isLoading"
            >Annuler
          </b-button>
        </b-card-footer>
      </b-card>
    </b-form>
  </validation-observer>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("stock");

import {
  BButton,
  BSpinner,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "CashRegisterOptionsCashRegisterForm",
  emit: ["toggle-component", "cash-register-handled"],
  props: ["loading"],
  components: {
    BFormTextarea,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BSpinner,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      stockData: {
        name: "",
        description: "",
      },
    };
  },
  watch: {
    currentStock: {
      handler: function (val) {
        this.stockData = { ...val };
      },
      immediate: true,
      deep: true,
    },
    getError: {
      handler: function (val) {
        if (val) {
          this.sendCashRegisterAlert(val.message, "danger");
          this.handleErrors(val.error);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      currentStock: "stock",
      getError: "errors",
    }),
    isLoading: {
      get: function () {
        return this.loading;
      },
      set: function (val) {
        this.$emit("update:loading", val);
      },
    },
    currentId() {
      return this.currentStock?.uid;
    },
  },
  methods: {
    ...mapActions(["addStock", "updateStock"]),
    ...mapMutations({
      setError: "SET_ERRORS",
    }),
    onSubmit: async function () {
      this.isLoading = true;
      this.setError(null);
      let response = {};

      try {
        const { uid, name, description } = this.stockData;

        const payload = {
          store_id: this.$route.params.id,
          data: {
            ...(uid
              ? {
                  id: uid,
                }
              : {}),
            name,
            description,
          },
        };
        // check if data has uid to be able to direct submission to the Add / Update action
        if (uid) {
          response = await this.updateStock(payload);
        } else {
          response = await this.addStock(payload);
        }

        // send notification and close the cash register form
        this.sendStockAlert(response.message, "success");
        this.resetForm();
      } catch (e) {
        // this.sendStockAlert(
        //   "Une erreur est survenue lors de la mise à jour de la caisse.",
        //   "danger"
        // );
      } finally {
        this.setError(null);
        this.isLoading = false;
      }
    },
    resetForm() {
      this.stockData = { ...this.currentStock };
      this.$emit("toggle-component", "Stocks List");
    },
    sendStockAlert(message, type) {
      this.$emit("stock-handled", message, "AlertTriangleIcon", type);
    },
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refFormObserver.setErrors({ [field]: message[0] });
      }
    },
  },
};
</script>
